// Styles

// Main styles

@import "./app-saas.scss";

// Hyper Icons
@import "./icons.scss";

// Additional bootstrap mods

.mw-25 {
  max-width: 25% !important;
}

.mr-0 {
  margin-right: 0 !important;
}

.mr-1 {
  margin-right: 0.25rem !important;
}

.ml--1 {
  margin-left: -0.25rem !important;
}

.ml--2 {
  margin-left: -0.5rem !important;
}

.RootComment:first-child {
  margin-top: 0 !important;
}

//
// buttons.scss
//

.btn {
  .mdi {
    &:before {
      line-height: initial;
    }
  }
}

@each $state in map-keys($theme-colors) {
  .btn-#{$state} {
    box-shadow: var(--#{$prefix}btn-box-shadow)
      rgba(var(--#{$prefix}#{$state}-rgb), 0.5);
  }

  .btn-soft-#{$state} {
    --#{$prefix}btn-color: var(--#{$prefix}#{$state});
    --#{$prefix}btn-bg: var(--#{$prefix}#{$state}-bg-subtle);
    --#{$prefix}btn-border-color: #{transparent};
    --#{$prefix}btn-hover-color: #{$white};
    --#{$prefix}btn-hover-bg: var(--#{$prefix}#{$state});
    --#{$prefix}btn-hover-border-color: var(--#{$prefix}#{$state});
    --#{$prefix}btn-active-color: #{$white};
    --#{$prefix}btn-active-bg: var(--#{$prefix}#{$state});
    --#{$prefix}btn-active-border-color: var(--#{$prefix}#{$state});
    --#{$prefix}btn-disabled-color: var(--#{$prefix}#{$state});
    --#{$prefix}btn-disabled-bg: var(--#{$prefix}#{$state});
    --#{$prefix}btn-disabled-border-color: var(--#{$prefix}#{$state});
    --#{$prefix}btn-focus-shadow-rgb: 0
      0
      0
      $btn-focus-width
      rgba(var(--#{$prefix}#{$state})-rgb, 0.5);
  }
}

.btn-light {
  --#{$prefix}btn-color: var(--#{$prefix}dark);
  --#{$prefix}btn-bg: var(--#{$prefix}light);
  --#{$prefix}btn-border-color: #{transparent};
  --#{$prefix}btn-hover-color: var(--#{$prefix}tertiary-color);
  --#{$prefix}btn-hover-bg: var(--#{$prefix}tertiary-bg);
  --#{$prefix}btn-hover-border-color: var(--#{$prefix}tertiary-bg);
  --#{$prefix}btn-active-bg: var(--#{$prefix}tertiary-bg);
  --#{$prefix}btn-active-color: var(--#{$prefix}tertiary-color);
  --#{$prefix}btn-active-border-color: var(--#{$prefix}tertiary-bg);
  --#{$prefix}btn-focus-shadow-rgb: 0 0 0 $btn-focus-width
    rgba(var(--#{$prefix}light-rgb), 0.5);
}

.btn-dark {
  --#{$prefix}btn-color: var(--#{$prefix}light);
  --#{$prefix}btn-bg: var(--#{$prefix}dark);
  --#{$prefix}btn-border-color: #{transparent};
  --#{$prefix}btn-hover-color: var(--#{$prefix}light);
  --#{$prefix}btn-hover-bg: var(--#{$prefix}dark);
  --#{$prefix}btn-hover-border-color: var(--#{$prefix}dark);
  --#{$prefix}btn-active-bg: var(--#{$prefix}dark);
  --#{$prefix}btn-active-color: var(--#{$prefix}light);
  --#{$prefix}btn-active-border-color: var(--#{$prefix}dark);
  --#{$prefix}btn-focus-shadow-rgb: 0 0 0 $btn-focus-width
    rgba(var(--#{$prefix}dark-rgb), 0.5);
}

.btn-outline-light {
  --#{$prefix}btn-color: var(--#{$prefix}dark);
  --#{$prefix}btn-bg: #{transparent};
  --#{$prefix}btn-border-color: var(--#{$prefix}light);
  --#{$prefix}btn-hover-color: var(--#{$prefix}tertiary-color);
  --#{$prefix}btn-hover-bg: var(--#{$prefix}tertiary-bg);
  --#{$prefix}btn-hover-border-color: var(--#{$prefix}tertiary-bg);
  --#{$prefix}btn-active-bg: var(--#{$prefix}tertiary-bg);
  --#{$prefix}btn-active-color: var(--#{$prefix}tertiary-color);
  --#{$prefix}btn-active-border-color: var(--#{$prefix}tertiary-bg);
  --#{$prefix}btn-focus-shadow-rgb: 0 0 0 $btn-focus-width
    rgba(var(--#{$prefix}light-rgb), 0.5);
}

.btn-outline-dark {
  --#{$prefix}btn-color: var(--#{$prefix}dark);
  --#{$prefix}btn-bg: #{transparent};
  --#{$prefix}btn-border-color: var(--#{$prefix}dark);
  --#{$prefix}btn-hover-color: var(--#{$prefix}light);
  --#{$prefix}btn-hover-bg: var(--#{$prefix}dark);
  --#{$prefix}btn-hover-border-color: var(--#{$prefix}dark);
  --#{$prefix}btn-active-bg: var(--#{$prefix}dark);
  --#{$prefix}btn-active-color: var(--#{$prefix}light);
  --#{$prefix}btn-active-border-color: var(--#{$prefix}dark);
  --#{$prefix}btn-focus-shadow-rgb: 0 0 0 $btn-focus-width
    rgba(var(--#{$prefix}dark-rgb), 0.5);
}

.btn-soft-dark {
  --#{$prefix}btn-hover-color: var(--#{$prefix}light);
  --#{$prefix}btn-active-color: var(--#{$prefix}light);
}

[data-bs-theme="dark"] {
  .btn-secondary {
    box-shadow: var(--#{$prefix}btn-box-shadow)
      rgba(var(--#{$prefix}light-rgb), 0.5);
  }
}

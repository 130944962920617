//
// tabs.scss
//

.nav-tabs,
.nav-pills {
  > li {
    > a {
      color: var(--#{$prefix}secondary-color);
      font-weight: $font-weight-semibold;
    }
  }
}

.nav-pills {
  > a {
    color: var(--#{$prefix}gray-700);
    font-weight: $font-weight-semibold;
  }
}

.bg-nav-pills {
  background-color: var(--#{$prefix}tertiary-bg);
}

//
// nav-bordered
//

.nav-tabs.nav-bordered {
  border-bottom: 2px solid var(--#{$prefix}border-color);

  .nav-item {
    margin-bottom: -1px;
  }

  li {
    a {
      border: 0;
      padding: 0.625rem 1.25rem;
    }
    a.active {
      border-bottom: 2px solid var(--#{$prefix}primary);
    }
  }
}

//
// ribbons.scss
//

.ribbon-box {
  position: relative;

  .ribbon {
    position: relative;
    clear: both;
    padding: 5px 12px;
    margin-bottom: 15px;
    box-shadow: 2px 5px 10px rgba($dark, 0.15);
    color: $white;
    font-size: 13px;
    font-weight: $font-weight-semibold;

    &:before {
      content: " ";
      border-style: solid;
      border-width: 10px;
      display: block;
      position: absolute;
      bottom: -10px;
      left: 0;
      margin-bottom: -10px;
      z-index: -1;
    }

    &.float-start {
      margin-left: -30px;
      border-radius: 0 3px 3px 0;
    }

    &.float-end {
      margin-right: -30px;
      border-radius: 3px 0 0 3px;

      &:before {
        right: 0;
      }
    }

    &.float-center {
      span {
        margin: 0 auto 20px auto;
      }
    }
  }

  .ribbon-content {
    clear: both;
  }

  @each $color, $value in $theme-colors {
    .ribbon-#{$color} {
      background: var(--#{$prefix}#{$color});

      &:before {
        border-color: var(--#{$prefix}#{$color}-text) transparent transparent;
      }
    }
  }

  /* Ribbon two */
  .ribbon-two {
    position: absolute;
    left: -5px;
    top: -5px;
    z-index: 1;
    overflow: hidden;
    width: 75px;
    height: 75px;
    text-align: right;

    span {
      font-size: 13px;
      color: $white;
      text-align: center;
      line-height: 20px;
      transform: rotate(-45deg);
      width: 100px;
      display: block;
      box-shadow:
        0 0 8px 0 rgba(var(--#{$prefix}dark-rgb), 0.08),
        0 1px 0 0 rgba(var(--#{$prefix}dark-rgb), 0.03);
      position: absolute;
      top: 19px;
      left: -21px;
      font-weight: $font-weight-semibold;

      &:before {
        content: "";
        position: absolute;
        left: 0;
        top: 100%;
        z-index: -1;
        border-right: 3px solid transparent;
        border-bottom: 3px solid transparent;
      }

      &:after {
        content: "";
        position: absolute;
        right: 0;
        top: 100%;
        z-index: -1;
        border-left: 3px solid transparent;
        border-bottom: 3px solid transparent;
      }
    }
  }

  @each $color, $value in $theme-colors {
    .ribbon-two-#{$color} {
      span {
        background: var(--#{$prefix}#{$color});

        &:before {
          border-left: 3px solid var(--#{$prefix}#{$color}-text);
          border-top: 3px solid var(--#{$prefix}#{$color}-text);
        }

        &:after {
          border-right: 3px solid var(--#{$prefix}#{$color}-text);
          border-top: 3px solid var(--#{$prefix}#{$color}-text);
        }
      }
    }
  }
}
